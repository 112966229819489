import React, { createContext, useReducer, useContext } from 'react';
import Home from './pages/Home';
import Rating from './pages/Rating';
import Welcome from './pages/Welcome';
import End from './pages/End'
import { Router, Route, Switch, Redirect } from './router';
import axios from 'axios';
import * as rax from 'retry-axios';

const interceptorId = rax.attach();

export const setAuthHeader = token => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${
    token
    }`;
}


const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'SET_REDIRECT':
      return {
        ...state,
        redirect: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  isAuthenticated: null,
  error: null,
  redirect: '/welcome'
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);

function PrivateRoute({ children, ...rest }) {
  const [state, dispatch] = useContext(Context);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        state.isAuthenticated != null ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
      }
    />
  );
}

const App = () => {
  return (
    <>
      <style type="text/css">{`
    @font-face {
      font-family: 'MaterialIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
    }

    @font-face {
      font-family: 'FontAwesome';
      src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
    }
  `}</style>
      <Store>
        <Router>
          <Switch>
            <Route path="/" exact component={Home} />
            <PrivateRoute path="/welcome">
              <Welcome />
            </PrivateRoute>
            <PrivateRoute path="/rating">
              <Rating />
            </PrivateRoute>
            <PrivateRoute path="/end">
              <End />
            </PrivateRoute>
            <Route component={Home} />
          </Switch>
        </Router>
      </Store>
    </>
  );
};

export default App;
