import React, { useContext } from 'react';
import { StyleSheet, View, Button, Text, Image } from 'react-native';
import { Divider } from 'react-native-elements';
import { useHistory } from '../router';
// import lion from '../lion.png';
import { ReactComponent as Lion } from './fox.svg';
import { Context, setAuthHeader } from '../App';
import { GoogleLogin } from 'react-google-login';
import { config } from '../Constants'


function Home() {
  let history = useHistory();
  const [state, dispatch] = useContext(Context);

  const responseGoogle = googleUser => {
    console.log('Response google!');
    // console.log(googleUser.tokenId)
    setAuthHeader(googleUser.tokenId)
    dispatch({ type: 'SET_AUTHENTICATED', payload: { sub: googleUser.googleId } });
    history.push(state.redirect);
  };

  const onFailure = event => {
    console.log(event)
  }


  const ratingPage = () => {
    console.log('BYPASS LOGIN');
    const expiredDummyToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjZiYzYzZTlmMThkNTYxYjM0ZjU2NjhmODhhZTI3ZDQ4ODc2ZDgwNzMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODIzMjc2MzYzODEzLWRqNDcwMDZkaXJzb2FhYmgwcnJoMGl1bm11bXNiMW0zLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODIzMjc2MzYzODEzLWRqNDcwMDZkaXJzb2FhYmgwcnJoMGl1bm11bXNiMW0zLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTEzNDMwNjcyNzU1MzgyOTU0NzI4IiwiZW1haWwiOiJzZG1jY2xhaW5AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJzLUJZMEVuU2libW9mR1B5TEpGVXpnIiwibmFtZSI6IlN0ZXZlIE1jQ2xhaW4iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2lSUkU3SkxWWlBkcEZoU1pDUmdiNU95X2JzR3A0VDhHNmVaUEJvY0dBPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IlN0ZXZlIiwiZmFtaWx5X25hbWUiOiJNY0NsYWluIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE1OTc3MjAyMDIsImV4cCI6MTU5NzcyMzgwMiwianRpIjoiYzM1MGJjYmVjMmYyNWRlZWRkOWQ0MTdlNzVmYTVjMzg0Nzg4OWNjNSJ9.dU7XkUUoo0U4siVIqgx_xqZsFNbZc4Pf_T6citUHAXy4c58c_RnDBU3MU-pj4PhXG_njMoBO6D6-R4DPiojnJbRT00oGeTfSoYeutJiPhP88bLE0-RQsi0ppienNyFpEArXn2dBRKvir6_InfWktpdbcWU-q8Cuco2me470xGrwv09sb_SfwJkeA3yxz-u1i3WwL8cJMpHAC_LI_pKUz31uAd94xT_RnngAwDIh11q0FeJzF5xoIrpTBcJHVozEPdhTB9pRW3HU5RKnKvgZ4Z27419YKTIMEPXSf1O9qmmELLl7JF3Q9tFInLH_NbDISmm02FPRIrPNMPH8gsdQVIQ';
    const dummyToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjdkYTc4NjNlODYzN2Q2NjliYzJhMTI2MjJjZWRlMmE4ODEzZDExYjEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODIzMjc2MzYzODEzLWRqNDcwMDZkaXJzb2FhYmgwcnJoMGl1bm11bXNiMW0zLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODIzMjc2MzYzODEzLWRqNDcwMDZkaXJzb2FhYmgwcnJoMGl1bm11bXNiMW0zLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTEzNDMwNjcyNzU1MzgyOTU0NzI4IiwiZW1haWwiOiJzZG1jY2xhaW5AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJnNm9uMGdXVnNoT2RqRE5VVW9YUGFnIiwibmFtZSI6IlN0ZXZlIE1jQ2xhaW4iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2lSUkU3SkxWWlBkcEZoU1pDUmdiNU95X2JzR3A0VDhHNmVaUEJvY0dBPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IlN0ZXZlIiwiZmFtaWx5X25hbWUiOiJNY0NsYWluIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE2MDI3MzI0NzMsImV4cCI6MTYwMjczNjA3MywianRpIjoiYTcyYTk1NzMwODdiNTkwNDJkODRkYzI2M2U5MTc3N2Y0YmIzNGNkMyJ9.FQcE9uKw1RJdmvviE7wv6nT2Jp--_9IxkBxJ59nXdm9UZEV-_bEAfGu2-BiuU-IonkPtuuLCc3_Qg3f1VBXW-dg2oh_V93xnhGJzumpriqk3MvNJRS9bL-EUaBYhGH6awInMMcwDhlxYxSZdr6NE_xwIokwEU0oNZyVYUWIw_gXLB76e7nWftR8FxDrgAo59U66sCTgcIblXc_0vh17kSj_aHOJj4461HMGhou79pq4nTQQzJdsp7BewV11XPeYI23d7aIwf_TDbRErhetFPi1iA7-mC6c_HDqi4Mfyc1OgpQYKIUcVEIoWUeQv5jrRH8It3mb96ObyO8PaJiUP25w';
    setAuthHeader(dummyToken)
    dispatch({
      type: 'SET_AUTHENTICATED',
      payload: { sub: '113430672755382954728' },
    });
    history.push('/rating');
  };
  var sign_in_button;
  if (!config.login) {
    sign_in_button = <Button onPress={ratingPage} title="Sign In" />
  } else {
    sign_in_button = <GoogleLogin
      clientId="823276363813-dj47006dirsoaabh0rrh0iunmumsb1m3.apps.googleusercontent.com"
      onSuccess={responseGoogle}
      onFailure={onFailure}
      scope="email"
      isSignedIn={true}
    />
  }


  return (
    <View style={styles.container}>
      {/* <Image source={lion} style={{marginTop: '10%', width: 75, height: 75, alignSelf: 'center'}}></Image> */}
      <View >
        <Lion style={{ marginTop: '30%', width: 75, height: 75, alignSelf: 'center' }} />
      </View>
      <View >
        <Text style={styles.heading}>Hi! Please help us with a two-part test!</Text>
        <Text style={styles.text}>We want to learn whether we can train an algorithm to quickly predict someone's preferred type. We’ll enter you to win one of four $25 Amazon gift cards for your time!</Text>
      </View>
      <View >
        <Text style={[styles.subheading, { textAlign: 'left', marginLeft: '10%' }]}>How it works:</Text>
        <Divider style={styles.dividerSolid} ></Divider>
        <Text style={[styles.text, { textAlign: 'left', marginLeft: '10%' }]}>
          <Text style={{ color: '#112948', backgroundColor: '#F2C94C', fontWeight: 'bold' }}>
            Part 1 (today):
          </Text> your responses will help us train an algorithm. Tap “Sign In with Google” below to begin.
        </Text>
        <Divider style={styles.dividerDashed} />
        <Text style={[styles.text, { textAlign: 'left', marginLeft: '10%' }]}><Text style={{ fontWeight: 'bold' }}>Part 2 (follow-up):</Text> see the results of our work and whether we can predict your preferred type.</Text>
        <Divider style={styles.dividerDashed} />
      </View>
      <View style={{ width: '30', alignSelf: 'center', marginTop: 30, marginBottom: '10%' }}>
        {sign_in_button}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#112948',
    justifyContent: 'flex-end',
  },
  logo: {
    width: 300,
    height: 300,
  },
  heading: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontSize: 24,
    fontStyle: 'normal',
    marginLeft: '15%',
    marginRight: '15%',
    textAlign: 'center',
    paddingTop: 30,
    paddingBottom: 30,
  },
  subheading: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontSize: 24,
    fontStyle: 'normal',
    marginLeft: '10%',
    marginRight: '10%',
    textAlign: 'left',
    paddingTop: 20,
    paddingBottom: 5,
  },
  h3: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontSize: 16,
    fontStyle: 'normal',
  },
  text: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontWeight: '300',
    fontSize: 16,
    fontStyle: 'normal',
    marginLeft: '10%',
    marginRight: '10%',
    textAlign: 'center',
  },
  dividerSolid: {
    backgroundColor: '#2D9CDB',
    height: 1,
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: 20,
    marginBottom: 20,
  },
  dividerDashed: {
    height: 1,
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: 10,
    marginBottom: 10,
    border: '1px dashed #2D9CDB'
  },
  button: {
    borderRadius: 3,
    padding: 20,
    marginVertical: 10,
    marginTop: 10,
    backgroundColor: '#1B95E0',
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
});

export default Home;
