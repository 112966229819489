import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { ReactComponent as Tada } from './tada.svg';

const ICON_SIZE = 30;

function End() {

  return (


    <View style={styles.container}>
      <Tada style={{
        marginTop: '10%',
        marginBottom: '20%',
        width: 115,
        height: 115,
        alignSelf: 'center'
      }} />
      <View >
        <Text style={styles.heading}>Castro has learned your type!</Text>
        <Text style={styles.text}>We will share your results during your in-person interview. We are so excited!</Text>
        <Text style={styles.heading}>THANK YOU!</Text>
      </View>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#112948',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  heading: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontSize: 20,
    fontStyle: 'normal',
    marginLeft: '15%',
    marginRight: '15%',
    textAlign: 'center',
    marginBottom: '5%',
  },
  text: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontWeight: '300',
    fontSize: 16,
    fontStyle: 'normal',
    marginLeft: '10%',
    marginRight: '10%',
    textAlign: 'center',
    marginBottom: '40%',
  },
});

export default End