import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  Image,
  Animated,
  PanResponder,
  Pressable,
} from 'react-native';
import { ProgressBar } from 'react-native-paper';
import { useHistory } from '../router';
import { config } from '../Constants';
import axios from 'axios';
import { Context } from '../App';
import { ReactComponent as BullsEye } from './fox.svg';


const image_api_url = config.url.API_URL + '/api/images';
const rating_api_url = config.url.API_URL + '/api/ratings';
const impression_api_url = config.url.API_URL + '/api/impressions';

const NUM_TO_RENDER = 3;
const MAX_RATINGS = 175;
const progressThresholds = [.15, .25, .40, .55, .70, .85, 1]
const BORDER_SIZE = 20;


const getHeight = () => {
  return Dimensions.get("window").height - BORDER_SIZE
}


const BullsEyeComponent = () => {
  const image_width = Dimensions.get('window').width / 2
  const circle_diameter = image_width / 4;
  const circle_radius = circle_diameter / 2;

  return <BullsEye style={{ position: 'absolute', top: '75%', left: '50%', marginLeft: -1 * (circle_radius + 5) }} height={circle_diameter + 10} width={circle_diameter + 10} />
}

const OneImage = ({ batch_id, id, url, onPress, selectedItem }) => {
  const image = useMemo(() => {
    // console.log('Render one image');

    var border = null;
    var Check = null;

    if (selectedItem) {
      border = '3px solid #FFAE42'
      Check = <BullsEyeComponent></BullsEyeComponent>
      console.log("RENDERING BULLSEYE", url)
    }

    return (
      <Pressable
        id={id}
        onPress={() => onPress(batch_id, id)}
      >
        <Image
          style={{
            flex: 1,
            height: getHeight() / 2,
            width: '50vw',
            resizeMode: 'cover',
            borderRadius: 0,
            background: '#112948',
            border: border
          }}
          source={url}
        />
        {Check}
      </Pressable>
    );
  }, [batch_id, id, url, onPress, selectedItem]);
  return <>{image}</>;
};

const ImageBatch = ({ item, onPress, selectedItem }) => {
  var selected = [null, null, null, null];
  if (selectedItem != null) {
    selected = item.images.map(({ id }) => id == selectedItem)
  }
  const batch = useMemo(() => {
    // console.log('Render one batch');
    return (
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View>
          <OneImage
            batch_id={item.batch_id}
            id={item.images[0].id}
            url={item.images[0].url}
            onPress={onPress}
            selectedItem={selected[0]}
          />
          <OneImage
            batch_id={item.batch_id}
            id={item.images[1].id}
            url={item.images[1].url}
            onPress={onPress}
            selectedItem={selected[1]}
          />
        </View>
        <View>
          <OneImage
            batch_id={item.batch_id}
            id={item.images[2].id}
            url={item.images[2].url}
            onPress={onPress}
            selectedItem={selected[2]}
          />
          <OneImage
            batch_id={item.batch_id}
            id={item.images[3].id}
            url={item.images[3].url}
            onPress={onPress}
            selectedItem={selected[3]}
          />
        </View>
      </View>
    );
  }, [item, ...selected]);

  return <>{batch}</>;
};

const Rater = () => {
  const [state, dispatch] = useContext(Context);
  const [imageBatches, setImageBatches] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState([]);
  const [progress, setProgress] = useState(0);
  const [fetching, setFetching] = useState(false);
  let history = useHistory();
  const pan = useRef(new Animated.Value(0)).current;

  const panResponder = React.useRef(
    PanResponder.create({
      // Ask to be the responder:
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onPanResponderGrant: (evt, gestureState) => {
        // The gesture has started. Show visual feedback so the user knows
        // what is happening!
        // gestureState.d{x,y} will be set to zero now
      },
      onPanResponderMove: (evt, gestureState) => {
        // console.log(gestureState.dy, gestureState.y0, gestureState.moveY, gestureState.vy)
        if (gestureState.dy < 0) {
          pan.setValue(gestureState.dy)
        }
      },
      onPanResponderRelease: (evt, gestureState) => {
        // The user has released all touches while this view is the
        // responder. This typically means a gesture has succeeded
        console.log("PAN RESPONDER RELEASED, dy " + gestureState.dy + ", vy " + gestureState.vy)
        if (gestureState.dy < -300 || gestureState.vy < -1) {
          console.log("PAN RESPONDER REACHED THRESHOLD")
          Animated.spring(pan, { toValue: -1 * (getHeight() + 100), velocity: 2 * gestureState.vy, stiffness: 100, damping: 10, mass: 1, overshootClamping: true }).start(() => {
            setActiveIndex(prevState => prevState + 1)
            pan.setValue(0)
          })
        }
        else if (gestureState.dy < 0) {
          console.log("FALLING BACK")
          Animated.spring(pan, { toValue: 0, stiffness: 100, damping: 10, mass: 1, overshootClamping: true }).start()
        }
      },
    })
  ).current;

  useEffect(() => {
    // Initial Load
    fetchImages(6);
    getImpressionCount();
  }, []);

  useEffect(() => {
    if (activeIndex > 0) {
      postRating(state.isAuthenticated.sub, imageBatches[activeIndex - 1].batch_id);
      if (!fetching && (imageBatches.length - activeIndex) < 3 * NUM_TO_RENDER) {
        fetchImages()
      }
    }
  }, [activeIndex]);

  const fetchImages = limit => {
    console.log('FETCHING IMAGES');
    setFetching(true);
    const height = Math.ceil(getHeight() / 2);
    const width = Math.ceil(Dimensions.get("window").width / 2);
    if (limit == null) {
      limit = 16
    }
    console.log("Requesting " + limit + " images with height " + height + " and width " + width)
    axios
      .get(image_api_url, { params: { height: height, width: width, limit: limit } })
      .then(res => {
        console.log("Retrieved images!", res.data.length)
        setImageBatches(prevState => {
          const current = prevState.map(item => item.batch_id)
          const newImages = res.data.filter(item => !current.includes(item.batch_id))
          return [...prevState, ...newImages]
        });
        setFetching(false);
      })
      .catch(error => {
        if (error != undefined && error.response != undefined && error.response.status === 403) {
          console.log("Invalid creds! Routing back to sign-in")
          dispatch({
            type: 'SET_AUTHENTICATED',
            payload: { sub: null },
          });
          dispatch({
            type: 'SET_REDIRECT',
            payload: '/rating',
          });
          history.push('/home');
        }
        console.log('Unexpected error when posting');
        console.log(error);
      });
  }

  function getImpressionCount() {
    axios
      .get(impression_api_url)
      .then(res => {
        console.log("Current impressions", res.data.count)
        setProgress(prevState => prevState + res.data.count);
      })
      .catch(error => {
        if (error != undefined && error.response != undefined && error.response.status === 403) {
          console.log("Invalid creds! Routing back to sign-in")
          dispatch({
            type: 'SET_AUTHENTICATED',
            payload: { sub: null },
          });
          dispatch({
            type: 'SET_REDIRECT',
            payload: '/rating',
          });
          history.push('/home');
        }
        console.log('Unexpected error when posting');
        console.log(error);
      });
  }

  const selectImage = (batch_id, selected_image_id) => {
    console.log(
      'user ' +
      state.isAuthenticated.sub +
      ' selected ' +
      selected_image_id +
      ' from batch ' +
      batch_id,
    );
    // console.log("Setting dummy token")
    // const dummyToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjZiYzYzZTlmMThkNTYxYjM0ZjU2NjhmODhhZTI3ZDQ4ODc2ZDgwNzMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODIzMjc2MzYzODEzLWRqNDcwMDZkaXJzb2FhYmgwcnJoMGl1bm11bXNiMW0zLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODIzMjc2MzYzODEzLWRqNDcwMDZkaXJzb2FhYmgwcnJoMGl1bm11bXNiMW0zLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTEzNDMwNjcyNzU1MzgyOTU0NzI4IiwiZW1haWwiOiJzZG1jY2xhaW5AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJzLUJZMEVuU2libW9mR1B5TEpGVXpnIiwibmFtZSI6IlN0ZXZlIE1jQ2xhaW4iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2lSUkU3SkxWWlBkcEZoU1pDUmdiNU95X2JzR3A0VDhHNmVaUEJvY0dBPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IlN0ZXZlIiwiZmFtaWx5X25hbWUiOiJNY0NsYWluIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE1OTc3MjAyMDIsImV4cCI6MTU5NzcyMzgwMiwianRpIjoiYzM1MGJjYmVjMmYyNWRlZWRkOWQ0MTdlNzVmYTVjMzg0Nzg4OWNjNSJ9.dU7XkUUoo0U4siVIqgx_xqZsFNbZc4Pf_T6citUHAXy4c58c_RnDBU3MU-pj4PhXG_njMoBO6D6-R4DPiojnJbRT00oGeTfSoYeutJiPhP88bLE0-RQsi0ppienNyFpEArXn2dBRKvir6_InfWktpdbcWU-q8Cuco2me470xGrwv09sb_SfwJkeA3yxz-u1i3WwL8cJMpHAC_LI_pKUz31uAd94xT_RnngAwDIh11q0FeJzF5xoIrpTBcJHVozEPdhTB9pRW3HU5RKnKvgZ4Z27419YKTIMEPXSf1O9qmmELLl7JF3Q9tFInLH_NbDISmm02FPRIrPNMPH8gsdQVIQ';
    // setAuthHeader(dummyToken)
    postRating(state.isAuthenticated.sub, batch_id, selected_image_id);
    setSelectedItem(prevState => [batch_id, selected_image_id])
    Animated.spring(pan, { toValue: -1 * (getHeight() + 100), delay: 250, stiffness: 50, damping: 10, mass: 4, overshootClamping: true }).start(() => {
      setActiveIndex(prevState => prevState + 1)
      pan.setValue(0)
    })
  };

  const postRating = (sub, batch_id, selected_image_id) => {
    var url;
    var body;
    if (selected_image_id == null) {
      url = impression_api_url;
      body = {
        sub: sub,
        batch_id: batch_id,
      }

    } else {
      url = rating_api_url;
      body = {
        sub: sub,
        batch_id: batch_id,
        selected_image_id: selected_image_id
      }
    }
    console.log('Posting to', url, 'with body', body);
    axios
      .post(url, body)
      .then(res => console.log(res))
      .catch(error => {
        if (error != undefined && error.response != undefined && error.response.status === 403) {
          console.log("Invalid creds! Routing back to sign-in")
          dispatch({
            type: 'SET_AUTHENTICATED',
            payload: { sub: null },
          });
          dispatch({
            type: 'SET_REDIRECT',
            payload: '/rating',
          });
          history.push('/home');
        }
        console.log('Unexpected error when posting');
        console.log(error);
      });
  };


  const renderImageBatch = (item, index) => {
    var selectedImage = null;
    if (selectedItem.length > 0 & selectedItem[0] == item.batch_id) {
      console.log("Item was selected by user!")
      selectedImage = selectedItem[1]
    }
    if (index < activeIndex) {
      return null;
    }
    else if (imageBatches[activeIndex].batch_id == item.batch_id) {
      console.log("Setting top image batch", item.batch_id)
      return (
        <Animated.View key={item.batch_id} style={[styles.imageBatch, { height: getHeight() }, { transform: [{ translateY: pan }] }]} {...panResponder.panHandlers}>
          <ImageBatch
            item={item}
            onPress={selectImage}
            selectedItem={selectedImage}
          />
        </Animated.View>
      )
    } else if ((index - activeIndex) < 3) {
      return (
        <Animated.View key={item.batch_id} style={[styles.imageBatch, { height: getHeight() }]}>
          <ImageBatch
            item={item}
            onPress={selectImage}
            selectedItem={selectedImage}
          />
        </Animated.View>
      )
    }
  }

  if (imageBatches.length === 0) {
    console.log('NO IMAGES YET');
    return <p>Loading profile...</p>;
  }
  console.log('GOT IMAGES ' + imageBatches.length);
  console.log('Active Index: ' + activeIndex);


  const rawProgressCount = (progress + activeIndex) % MAX_RATINGS
  const rawProgressFloat = rawProgressCount / MAX_RATINGS;
  const rawProgressPercent = 100 * rawProgressFloat;

  if (rawProgressFloat == 0.0 && (progress + activeIndex) > 0) {
    history.push('/end');
  }


  var progressThreshold;
  for (var i = 0; i < progressThresholds.length; i++) {
    progressThreshold = progressThresholds[i]
    if (rawProgressFloat < progressThreshold) {
      break;
    }
  }

  const progressThresholdPercent = 100 * progressThreshold
  const progressFloat = rawProgressFloat / progressThreshold;

  console.log(imageBatches.map(imageBatch => imageBatch.batch_id))


  return (
    <View style={{
      flex: 1,

    }}
    >
      <View>
        <ProgressBar progress={progressFloat} color="#42B980" style={{ height: BORDER_SIZE }}></ProgressBar>
        <Text style={styles.progressBarText}>{`${Math.floor(rawProgressPercent)}% Done | Next Target: ${Math.round(progressThresholdPercent)}%`}</Text>
      </View>
      {imageBatches.map(renderImageBatch).reverse()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  slideContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  logo: {
    width: 300,
    height: 300,
  },
  button: {
    borderRadius: 3,
    padding: 20,
    marginVertical: 10,
    marginTop: 10,
    backgroundColor: '#1B95E0',
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  slideContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  slide1: {
    backgroundColor: 'rgba(20,20,200,0.3)',
  },
  slide2: {
    backgroundColor: 'rgba(20,200,20,0.3)',
  },
  regularSlide: {
    backgroundColor: '#282c34',
  },
  loveSlide: {
    backgroundColor: 'rgba(255,192,203,0.3)',
  },
  progressBar: {
    height: BORDER_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressBarText: {
    position: 'absolute',
    color: '#000000',
    alignSelf: 'center',
    textAlign: 'center',
  },
  imageBatch: { width: '100vw', position: 'absolute', marginTop: BORDER_SIZE }
});

export default Rater;
