import React, { useState } from 'react';
import { StyleSheet, Text, View, ImageBackground } from 'react-native';
import { useHistory } from '../router';
import { Card, Divider, Icon, Button } from 'react-native-elements'

import backgroundImage from './welcome-background-compressed.jpg';
import { ReactComponent as BullsEye } from './fox.svg';
import { ReactComponent as SwipeUp } from './swipe-up-color.svg';

const ICON_SIZE = 30;

function Welcome() {
  let history = useHistory();
  const [card, setCard] = useState('bullseye')

  const startRating = () => {
    history.push('/rating');
  };

  var WelcomeCard;
  if (card == 'bullseye') {
    WelcomeCard = <>
      <Text style={styles.heading}>Tap who you are most attracted to{"\n"}<Text style={{ fontWeight: 300 }}>in each group of four</Text></Text>
      <Divider style={styles.dividerSolid} />
      <View style={{ flex: 1, justifyContent: "center", flexGrow: 2, flexDirection: 'row' }}>
        <View>
          <Icon
            size={ICON_SIZE}
            style={styles.icon}
            name='user-circle'
            color='white'
            type='font-awesome' />
          <Icon
            size={ICON_SIZE}
            style={[styles.icon, { marginTop: 10 }]}
            name='user-circle'
            color='white'
            type='font-awesome' />
        </View>
        <View>
          <BullsEye style={{ marginTop: -15, marginBottom: 15, marginLeft: 5 }} />
          <Icon
            size={ICON_SIZE}
            style={[styles.icon, { marginLeft: 10 }]}
            name='user-circle'
            color='white'
            type='font-awesome' />
        </View>
      </View>
      <Button
        buttonStyle={{ backgroundColor: '#219653' }}
        style={[styles.button, { marginTop: -5 }]}
        onPress={() => setCard("swipe")}
        title="Next"
      />
    </>
  } else if (card == 'swipe') {
    WelcomeCard = <>
      <Text style={styles.heading}><Text style={{ fontWeight: 300 }}>If you’re not attracted to anyone, </Text>swipe up for a new group</Text>
      <Divider style={styles.dividerSolid} />
      <View style={{ flex: 1, justifyContent: "center", flexGrow: 2, flexDirection: 'row' }}>
        <SwipeUp height={50} style={{ marginRight: -25 }} />
        <Text style={[styles.heading, { marginTop: 10 }]}>Swipe up</Text>
      </View>
      <Button
        buttonStyle={{ backgroundColor: '#219653' }}
        style={[styles.button, { marginTop: 50 }]}
        onPress={startRating}
        title="Next"
      />
    </>
  }

  return (

    <ImageBackground source={{ uri: 'https://castroapp.imgix.net/welcome-background.jpg?h=256&w=256&auto=compress&blur=50&s=79e40264c89f5cc4baf3583c3fd5a404' }} style={{ flex: 1, resizeMode: "cover" }}>
      <View style={styles.container}>
        <Card containerStyle={styles.card}>
          {WelcomeCard}
        </Card>
      </View>
    </ImageBackground>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    backgroundColor: '#112948',
    border: 0,
    height: '40%',
    width: '75%',
    justifyContent: 'flex-start',
  },
  button: {
    width: '30%',
    alignSelf: 'center',
    // marginBottom: '25%'
  },
  dividerSolid: {
    backgroundColor: '#2D9CDB',
    height: 1,
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: 10,
    marginBottom: 20,
  },
  heading: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontSize: 20,
    fontStyle: 'normal',
    marginLeft: '15%',
    marginRight: '15%',
    textAlign: 'center',
  },
  icon: {
    width: 50,
    height: 50,
  }
});

export default Welcome