const prod = {
  login: true,
  url: {
    API_URL: 'https://castroapp.com',
  },
};
const dev = {
  login: false,
  url: {
    API_URL: 'https://castroapp.com'
    //API_URL: 'http://192.168.1.18:8080',
    // API_URL: 'http://192.168.1.10:8080',
    // API_URL: 'http://192.168.0.101:8080',
    //API_URL: 'http://localhost:8080',
  },
};
console.log(process.env.NODE_ENV)
// export const config = process.env.NODE_ENV === 'development' ? dev : prod;
export const config = prod;